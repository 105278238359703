<template>
  <div class="container footer--directory">
    <div class="footer--title mb-3">
      {{ $t('footer.directory.header') }}
    </div>
    <div class="d-flex flex-wrap">
      <div class="d-flex flex-wrap mb-3 footer--nav-wrapper">
        <router-link
          :to="property.url"
          class="footer--nav mb-2"
          v-for="property in properties"
          :key="property.url"
        >
          {{
            `${property.propertyType} ${$t('general.rented')} ${$t('general.in')} ${property.name}`
          }}
        </router-link>
        <router-link to="/sewa/search" class="footer--nav mb-2">
          {{ `${$t('home.seeAll')} ${$t('general.property')} ${$t('general.rented')} >` }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    properties: [
      {
        url: '/sewa/search/rumah/Kota-Bogor/?property_type_id=%255B26%255D&category=city&category_filter_id=3271',
        name: 'Bogor',
        propertyType: 'Rumah',
      },
      {
        url: '/sewa/search/rumah/Kota-Tangerang-Selatan/?property_type_id=%255B26%255D&category=city&category_filter_id=3674',
        name: 'Tangerang Selatan',
        propertyType: 'Rumah',
      },
      {
        url: '/sewa/search/serviced%20office/Kota-Jakarta-Selatan/?property_type_id=%255B14%255D&category=city&category_filter_id=3171',
        name: 'Jakarta Selatan',
        propertyType: 'Serviced Office',
      },
      {
        url: '/sewa/search/serviced%20office/Kota-Tangerang-Selatan/?property_type_id=%255B14%255D&category=city&category_filter_id=3674',
        name: 'Tangerang Selatan',
        propertyType: 'Serviced Office',
      },
      {
        url: '/sewa/search/apartemen/Kota-Jakarta-Timur/?property_type_id=%255B27%255D&category=city&category_filter_id=3172',
        name: 'Jakarta Timur',
        propertyType: 'Apartemen',
      },
      {
        url: '/sewa/search/apartemen/Kota-Jakarta-Barat/?property_type_id=%255B27%255D&category=city&category_filter_id=3174',
        name: 'Jakarta Barat',
        propertyType: 'Apartemen',
      },
      {
        url: '/sewa/search/co-working%20space/Kota-Bandung/?property_type_id=%255B8%255D&category=city&category_filter_id=3273',
        name: 'Bandung',
        propertyType: 'Co-Working',
      },
      {
        url: '/sewa/search/co-working%20space/Kota-Tangerang/?property_type_id=%255B8%255D&category=city&category_filter_id=3671',
        name: 'Tangerang',
        propertyType: 'Co-Working',
      },
      {
        url: '/sewa/search/virtual%20office/Kota-Jakarta-Pusat/?property_type_id=%255B12%255D&category=city&category_filter_id=3173',
        name: 'Jakarta Pusat',
        propertyType: 'Virtual Office',
      },
      {
        url: '/sewa/search/virtual%20office/Kota-Jakarta-Barat/?property_type_id=%255B12%255D&category=city&category_filter_id=3174',
        name: 'Jakarta Barat',
        propertyType: 'Virtual Office',
      },
      {
        url: '/sewa/search/ruko/Kota-Bekasi/?property_type_id=%255B1%255D&category=city&category_filter_id=3275',
        name: 'Bekasi',
        propertyType: 'Ruko',
      },
      {
        url: '/sewa/search/ruko/jakarta/?property_type_id=%255B1%255D',
        name: 'Jakarta',
        propertyType: 'Ruko',
      },
    ],
  }),
};
</script>

<style lang="scss" scoped></style>
